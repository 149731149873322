import React, { useState, ChangeEvent } from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import medPic from '../static-images/med_pattern.jpg'
import { theme } from '../common/site-variables';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {generateKey} from '../helper/key-generator'
import Snippet from './snippet';

const emergencyList = [
    '1. Automatic external defibrillator or AED (Defib Firstmedic and Power Heart)',
    '2. Self-inflating resuscitation bag & its accessories',
    `
    3. Minimum 3 portable 10-L aluminum oxygen cylinders on board with oxygen regulators to deliver the oxygen at 
    up to 15 L of oxygen per minute
    `,
    '4. One addtional 2.8-L oxygen cylinder for portability, especially when transferring paitents to and from the ambulance'
];

const firstAidList = [
    '1. Standard ambulance automatic loading stretcher, scoop stretcher, 2-fold stretcher, & spinal canvas for evacuation purposes',
    '2. Long spinal board, KED, head immobilizer, short spinal board and cervical collars',
    '3. Standard Trauma bag with first aid equipment'
];

const medicalList = [
    `1. Blood Pressure Monitor with printer to monitor blood pressure and oxygen saturation in the arterial blood 
    (by Nellcor finger probe).`,
    '2. Thermometer',
    '3. Glucometer',
    '4. Anaesthetic grade suction apparatus (displace ≈ 25 L air/min)'
];

const medicineList = [
    '1. Nitroglycerine spray for patients with ischaemic heart disease; salbutamol inhaler for patients with bronchial asthma.',
    '2. Over-the-counter medications (e.g., Panadol, Robitussin cough syrup, lozenges, calamine lotion, etc.).',
];

const disposablesList = [
    '1. Disinfectant solutions or wipes for hands and/or medical equipment.',
    '2. Disposable paper towel dispenser, paper towels.',
    '3. N95 masks, gloves and gown for personal protection.'
];

const otherFeatList = [
    '1. Laser examination torch light.',
    '2. 220V AC power supply on board to power medical equipment.',
    '3. Fire Extinguisher'
];

const manPowerList = [
    '1. Specialist doctors & nurses.',
    '2. Female medics or registered nurse to attend to patients.',
];

const specialEquipList = [
    `
        1. Airway bags: A complete setup for endotracheal intubation featuring two laryngoscopes with various sizes of 
        blades and extra batteries, various sizes of endotracheal tubes, Stylets, McGill’s forceps
        Artery forceps, tapes, cotton ties, syringes, scissors, etc.
    `,

    `
        2. Emergency Procedural Bag including Portex Minitracheotomy set, Portex Cricothyrodotomy set, Portex chest tube 
        set & drainage system, Needle holder, artery forceps, tissue forceps, suture scissors, Ethicon MerSilk sutures, 
        disposable scalpels for emergency minor procedures and emergency drugs
    `,

    '3. Intravenous drip sets and fluids',
    '4. Terumo (TE-171) infusion pump',
    '5. Graesby (3100, 3400, 3500) syringe pump',
    '6.  Camp beds for light casualties to rest',
    '7.  Additional 2-fold stretchers, long spinal boards, short spinal boards, KED, head immobilizers & cervical collars'
    
]

interface PropTypes {
    isMobile: boolean;
}

const AmbulanceInfo = (props: PropTypes) => {
    const { isMobile } = props;

    const styles = css(`
        width: 100%;
                
        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 20vh;
            background: linear-gradient(rgba(255,255,255, .5), rgba(255,255,255, .7)), url(${medPic});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            
            h1 {
                color: ${theme.blue};
                text-align: center;
                font-size: ${isMobile ? 1.2 : 1.5}rem;
            }
        }

        .features {
            width: inherit;
            padding: 5%;

            h2 {
                margin: 7% 0;
                text-align: center;
                color: ${theme.lightGray};
                font-size: ${isMobile ? 1.1 : 1.4}rem;
            }
        }

        h3 {
            font-size: ${isMobile ? 1.2 : 1.5}rem;
        }

        .active {color: ${theme.red}}
    `);

    const [expandedPanel, setExpandedPanel] = useState<string | false>('panel1');
    
    const handleChange = (panel: string) => (e: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpandedPanel(isExpanded ? panel : false);
        console.log(panel);
        
    }

    return (
        <section id="ambulance_features" css={styles}>
            <div className="header">
                <h1>State of the Art Ambulance Features</h1>
            </div>

            <div className="features">

                <h2>Our emergency ambulances are equipped with the following:</h2>

                <ExpansionPanel expanded={expandedPanel === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel1' && 'active'}>Emergency</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {emergencyList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandedPanel === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel2' && 'active'}>First Aid</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {firstAidList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandedPanel === 'panel3'} onChange={handleChange('panel3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel3' && 'active'}>Medical Equipment</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {medicalList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandedPanel === 'panel4'} onChange={handleChange('panel4')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel4' && 'active'}>Medicines</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {medicineList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandedPanel === 'panel5'} onChange={handleChange('panel5')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel5' && 'active'}>Disposables</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {disposablesList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandedPanel === 'panel6'} onChange={handleChange('panel6')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel6' && 'active'}>Other Features</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {otherFeatList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                            <p style={{color: theme.blue}}>
                            Note: There are no intravenous drip set or fluid on board because our medics are not allowed to 
                            perform invasive procedures.
                        </p>
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            
                <h2>Upon request, we can provide the following:</h2>

                <ExpansionPanel expanded={expandedPanel === 'panel7'} onChange={handleChange('panel7')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel7' && 'active'}>Manpower</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {manPowerList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandedPanel === 'panel8'} onChange={handleChange('panel8')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 className={expandedPanel === 'panel8' && 'active'}>Specialized Medical Equipment</h3>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <List>
                            {specialEquipList.map((item, index) => (
                                <ListItem key={generateKey(index)}>{item}</ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>

            <Snippet>
                Standard ambulance personnel consists of one driver and one or two Medics, and all are certified with Advance First 
                Aid (Emergency Medical Technician – Basic) qualification and BCLS (Basic Cardiac Life Support)
            </Snippet>
        </section>
    )
}

export default AmbulanceInfo
