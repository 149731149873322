import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Subscription from '../subscription'
import EnquiryForm from '../enquiry-form'
import CopyrightOutlined from '@material-ui/icons/CopyrightOutlined'
import { Media } from '../../responsive/media'
import { getStyles } from './footer-styles'
import { Button } from '@material-ui/core'

interface PropTypes {
    isMobile: boolean;
}

const FooterHTML = (props: PropTypes) => {
        
    const { isMobile } = props;

    return (
        <div css={getStyles(isMobile)}>
            <Subscription isMobile={isMobile} />

            <div className="footerContent">
                <div className="enquiry">
                    <EnquiryForm />
                    <span className="copy"><CopyrightOutlined /> 2019 Gentlecare Ambulance Service</span>
                </div>

                <div className="contactDiv">
                    <address>
                        <span>57 Grants Crescent Hampton Green Spanish Town, Jamaica</span>
                        <span>
                            <a href="tel:8766317521">876.631.7521</a> &nbsp; | &nbsp;
                            <a href="tel:8765752273">876.575.CARE(2273)</a>
                        </span>
                        <Button href="https://wa.me/message/XQ2NMSEOL4EEE1">
                            WhatsApp &nbsp;
                            <svg enable-background="new 0 0 24 24" height="50" viewBox="0 0 24 24" width="50" xmlns="http://www.w3.org/2000/svg"><path d="m20.52 3.449c-2.28-2.204-5.28-3.449-8.475-3.449-9.17 0-14.928 9.935-10.349 17.838l-1.696 6.162 6.335-1.652c2.76 1.491 5.021 1.359 5.716 1.447 10.633 0 15.926-12.864 8.454-20.307z" fill="#eceff1"/><path d="m12.067 21.751-.006-.001h-.016c-3.182 0-5.215-1.507-5.415-1.594l-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-8.793 10.745-13.19 16.963-6.975 6.203 6.15 1.848 16.875-7.026 16.875z" fill="#024D94"/><path d="m17.507 14.307-.009.075c-.301-.15-1.767-.867-2.04-.966-.613-.227-.44-.036-1.617 1.312-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z" fill="#fafafa"/></svg>
                        </Button>
                        <a href="mailto:info@gentlecareambulance.com">info@gentlecareambulance.com</a>
                    </address>

                    <span className="copy"><CopyrightOutlined /> 2019 Gentlecare Ambulance Service</span>

                    <span className="credits">
                        Design and code by {' '}
                        <a href="https://swizzdigital.com/" target="_blank" className="swizzD">Swizz Digital</a></span>
                </div>
            </div>
        </div>
    )
}

const Footer = () => {

    return (
        <footer id="contact">
            <Media lessThan="lg">
                <FooterHTML isMobile={true} />
            </Media>

            <Media at="lg">
                <FooterHTML isMobile={false} />
            </Media>
        </footer>
    );
}

export default Footer;