import React, { useState, useEffect, ReactEventHandler } from 'react'

/**@jsx jsx */
import { jsx, css } from '@emotion/core'
import { TextField, Button, Snackbar } from '@material-ui/core'
import { theme } from '../common/site-variables'

import axios from 'axios'
import { FormControl, inititalValues } from './enquiry-form'
import { isNull, emailIsValid } from '../helper/formValidors'

interface PropTypes {
    isMobile: boolean;
}

interface FormData {
    fullName: FormControl;
    email: FormControl;
    valid: boolean;
}

const Subscription = (props: PropTypes) => {
    const { isMobile } = props;

    const subscribeContStyles = css(`
        display: flex;
        flex-direction: ${isMobile ? 'column' : 'row'};
        align-items: center;
        justify-content: center;
        width: 100%;
        height: ${isMobile ? 'auto' : '20vh'};
        background-color: ${theme.blue};
        padding-top: ${isMobile ? 5 : 0}%;

        h3 {
            color: white;
        }
        

        form {
            display: flex;
            justify-content: space-between;
            flex-direction: ${isMobile ? 'column' : 'row'};
            padding: 2%; 

            .textField {
                background-color: rgba(24,71,99, .4);
                margin-right: 2%;
                margin-bottom: ${isMobile ? 5 : 0}%;

                .MuiInputBase-input {
                    color: white;
                }
                .MuiInputLabel-outlined {
                    color: white;
                }
            }

            button {
                color: white;
                background-color: ${theme.red};
            }
        }
    `);

    const initForm = () => {
        return {
            fullName: {...inititalValues},
            email: {...inititalValues},
            valid: false
        };
    }

    const [formValid, setFormValid] = useState(false);
    const [formData, setFormData] = useState<FormData>(initForm());

    useEffect(() => {
        setFormValid(formData.email.valid);
    }, [formData]);

    const handleChange = (e: React.FormEvent) => {
        const target = e.target as HTMLInputElement;
        const name = target.name;
        const value = target.value;
        const notNull = !isNull(value);
        const emailValid = emailIsValid(value);

        switch (name) {
            case 'fullName' : setFormData({
                    ...formData,
                    fullName: {value: value, touched: true, valid: true}
            }); break

            case 'email' : 
                let emailErrorText = formData.email.errorText ? formData.email.errorText : '';
                if (notNull) {
                    emailErrorText = !emailValid ? 'Please enter a valid email' : '';    
                } else {
                    emailErrorText = 'Email is required';
                }
                setFormData({
                    ...formData,
                    email: {value: value, touched: true, valid: notNull && emailValid, errorText: emailErrorText}
                }); 
            break

        }
    }

    const [snackBar, setSnackBar] = React.useState({open: false, success: false, message: ''});

    const handleSumbit = (e: React.FormEvent) => {
        if (!formValid) {
            setSnackBar({open: true, success: false, message: 'Please enter a valid email'});
            e.preventDefault();
        }
    }

    const snackbarStyles = css(`
        * {
            background: ${snackBar.success ? 'green' : 'red'};
            color: white;
        }
    `);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar({...snackBar, open: false});
    };

    return (
        <div css={subscribeContStyles}>
            <h3 className="noMargin"><span>Sign me up</span> for news and updates</h3>

            <form className="noMargin" onSubmit={handleSumbit} 
                action="http://gentlecareambulance.com/mailman/subscribe/subscribers_gentlecareambulance.com">

                <TextField name="fullName" className="textField" label="name" variant="outlined" size="small"
                    value={formData.fullName.value} onChange={handleChange} />

                <TextField name="email" className="textField"  label="email" variant="outlined" size="small"
                    error={formData.email.touched && !formData.email.valid} helperText={formData.email.errorText}
                    value={formData.email.value} onChange={handleChange} />

                <Button type="submit" variant="contained">Subscribe</Button>
            </form>

            <Snackbar css={snackbarStyles} open={snackBar.open} 
                autoHideDuration={6000} onClose={handleClose} message={snackBar.message} />
        </div>
    );
}

export default Subscription;