import React from 'react'
import { animated, AnimatedValue } from 'react-spring'
import AnimatedCard from './animated-card'
import ambo from '../static-images/ambulance_vehicle.png'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

type ScreenSize = 's' | 'md' | 'lg';

interface PropTypes {
    screenSize: ScreenSize;
    scrollTop: AnimatedValue<any>; 
}

const AmbulanceAnimation = (props: PropTypes) => {

    const { screenSize, scrollTop } = props;

    const amboStyles = css(`
        position: absolute;
        bottom: ${screenSize === 'lg' ? 0 : 35}%;
        right: 0;
        width: 80%;

        .ambulanceAniCont {

            width: 100%;

            img {
                width: inherit;
            }
        }
    `);

    let xFactor: number, yFacter: number;
    switch(screenSize) {
        case 'lg' : xFactor = 8; yFacter = 8; break;
        case 'md' : xFactor = 8; yFacter = 9; break;
        case 's' : xFactor = 25; yFacter = 12;
    }

    const transitionStyles = scrollTop.st.interpolate((st) => `translate(${-st / xFactor}px, ${st / yFacter}px)`);

    return (
        <animated.div css={amboStyles} style={{transform: transitionStyles}} 
          className="ambulanceCont">
          <AnimatedCard className="ambulanceAniCont">
            <img src={ambo} alt="Ambulance Vehicle"/>
          </AnimatedCard>
        </animated.div>
    )
}

export default AmbulanceAnimation
