import React, { ReactNode } from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { theme, headerHeight } from '../common/site-variables'
import { Grid, Button } from '@material-ui/core'
import medPic from '../static-images/med_bg.png'

interface PropTypes {
    isMobile: boolean;
    children: ReactNode;
    toggleApplicationForm: () => void;
}

const Services = (props: PropTypes) => {
    const {children, toggleApplicationForm, isMobile} = props;

    const styles = css(`
        position: relative;
        width: 100%;
        min-height: 100vh;
        .gridCont {

            .headerGrid {
                display: flex;
                justify-content: center;
                background-color: ${theme.blue};

                .header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    color: white;
                    padding: ${isMobile ? `${headerHeight} 0` : headerHeight};
                    background: linear-gradient(127deg, rgba(2,77,148,.65) 0%, rgba(9,92,170,.8) 100%), url(${medPic});
                    background-repeat: no-repeat;
                    background-size: ${isMobile ? '100% 60%' : 'cover'};
                    background-position: center;
                }
            }

            .gridItem {
                min-height: 20vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 5%;
                
                .highlight {
                    color: ${theme.red};
                }


                p {
                    color: ${theme.gray};
                    width: 65%;
                }

                button {
                    background-color: ${theme.blue};
                    color: white;
                    width: 65%;
                }

            }

            .border {
                border-top: solid rgba(220,220,220, .7) 1px;
                border-bottom: solid rgba(220,220,220, .7) 1px;
                align-items: ${isMobile ? 'center !important' : 'flex-start'};

                p {
                    text-align: ${isMobile ? 'center !important' : 'initial'};
                }
            }

            .borderLeft {
                border-left: solid rgba(220,220,220, .7) 1px;

                p {
                    text-align: ${isMobile ? 'center !important' : 'right'};
                }
            }

            .right {
                align-items: flex-end;
                
                * {
                    text-align: right;
                }
            }

            .graphics {
                position: relative;
                padding-bottom: ${isMobile ? 50 : 5}%;
            }
        }

    `);

    

    return (
        <section css={styles} >
            

            <Grid container className="gridCont">
                <Grid lg={12} md={12} sm={12} xs={12} className="gridItem headerGrid">
                    <div className="header">
                        <h1>What We Offer?</h1>
                    </div>
                </Grid>

                <Grid lg={6} md={6} sm={12} xs={12} className="gridItem">
                </Grid>

                <Grid lg={6} md={6} sm={12} xs={12} className="gridItem right graphics">
                    <h1>Transportation <br/> <span className="highlight">Management</span></h1>
                    <p>
                        Emergency ambulance transportation for residential citizens, facilities, hotels, business sector entities. 
                        As well as, ambulance Services for doctor visits, dialysis and hospital discharges. Our 24/7 call 
                        centre provides scheduling solutions for ambulance and wheelchair van transportation.
                    </p>
                    {children}
                </Grid>

                <Grid lg={6} md={6} sm={12} xs={12} className="gridItem">
                    <h1>24Hrs Medical <br/> <span className="highlight">Help-line</span></h1>
                    <p>
                        Call via call centre to EMTs, doctors, RN nurses, for medical advice & pre-hospital care pick-ups, and
                        medication pick-ups / drop-offs.
                    </p>
                    
                    <Button onClick={toggleApplicationForm}>Apply for Membership</Button>
                </Grid>


                <Grid lg={6} md={6} sm={12} xs={12} className="gridItem">
                </Grid>


                <Grid lg={6} md={6} sm={12} xs={12} className="gridItem border">
                    <h1>Pharmacy <br/> <span className="highlight">Services</span></h1>
                    <p>
                        We provide pick-up and drop-off services for the following Pharamacies:
                    </p>
                    <ul>
                        <li>Lifeline Pharmacy</li>
                        <li>7LC Pharmacy</li>
                        <li>Cumbergrove Pharmacy</li>
                        <li>St. Jago Pharmacy</li>
                        <li>York Pharmacy</li>
                    </ul>
                    <p className="highlight">Gentlecare's Members get 10% discount at participating pharmacies</p>
                </Grid>

                <Grid lg={6} md={6} sm={12} xs={12} className="gridItem right border borderLeft">
                    <h1>Medical <br/> <span className="highlight">Standby</span></h1>
                    <p>
                        At sports events and social events, our ever-ready team of trained personnel is there to safeguard lives 
                        in case of accidents.
                        Every time you hire us, you shall get two or three first-responders on standby (one driver and one or two 
                        medic(s)). We can provide you female/ male medics/ paramedics, doctors and/or staff nurses upon request. 
                        Plus our ambulances, which we ensure is constantly well-equipped.
                    </p>
                </Grid>

            </Grid>
        </section>
    )
}

export default Services
