import { css } from '@emotion/core'
import { headerHeight, theme, mobiHeaderHeight } from '../../common/site-variables';

export const getHeaderStyles = (isMobile: boolean) => {
    return css(`
        position: fixed;
        height: ${ isMobile ? mobiHeaderHeight : headerHeight};
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: transparent;
        box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.1);
        z-index: 99;

        .toolBar {
        width: 100%;
        position: relative;
        padding: 0% 5%;
        justify-content: space-between;

        .logoCont {
            width: ${isMobile ? 50 : 15}%;
            margin-right: 5%;

            a {
                width: 100%;
                text-decoration: none;
            }
        }

        .links {
            min-width: 30%;

            a {
            color: #5D5E61;
            text-decoration: none;
            padding: 0 2%;
            font-size: 1rem;
            }
        }

        button {
            background-color: ${theme.blue};
            color: white;

        }

        
        }

        .dropdown {
            position: absolute;
            width: inherit;
            height: calc(100vh - ${isMobile ? mobiHeaderHeight : headerHeight});
            left: 0;
            top: ${isMobile ? mobiHeaderHeight : headerHeight};
            background: ${theme.red};
            z-index: -1;
            padding: 0  0 5% 0;
            opacity: 0;
            transform-origin: top;

            display: ${isMobile ? 'flex' : 'none'};
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            a {
                color: white;
                font-size: 2rem;
                text-decoration: none;
            }

            button {
                background-color: white;
                color: ${theme.red};
                font-size: 1.2rem;

            }
        }

        .headerBg {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: ${isMobile ? 1 : .9};
            z-index: -1;
        }

    `);

}