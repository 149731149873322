/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import { StylesProvider } from '@material-ui/core'
import { Global } from '@emotion/core'
import { headerHeight, mobiHeaderHeight } from '../common/site-variables'
import { useSpring } from 'react-spring'
import Cursor, { cursorSize } from './cursor'
import { useMediaQuery } from 'react-responsive'
import { Media } from '../responsive/media'

const Layout = ({ children, onScroll }) => {
  const [pointer, setPointer] = useState(false);
  const isMobile = useMediaQuery({maxWidth: 1000});

  const cursorCoords = (x, y) => {
    return [x, y];
  }

  const [spring, setSpring] = useSpring(() => ({xy: [0, 0], config: {mass: 10 , tension: 2000, friction: 140}}));
  const [spring2, setSpring2] = useSpring(() => ({xy: [0, 0], config: {mass: 10 , tension: 800, friction: 140}}));

  const trans = (x?, y?) => `translateX(${x - (cursorSize.cursor / 2)}px) translateY(${y - cursorSize.cursor / 2}px)`;
  const trans2 = (x?, y?) => `translateX(${x - cursorSize.trail / 2}px) translateY(${y - cursorSize.trail / 2}px)`;
  const trans3 = (x?, y?) => `translateX(${x - cursorSize.pointer / 2}px) translateY(${y - cursorSize.pointer / 2}px)`;

  const handleMouseMove = (e) => {
    if (isMobile) {
      return;
    }
    const event = e as MouseEvent;
    const target = event.target as HTMLElement;
    setSpring({xy: cursorCoords(event.pageX, event.pageY)})
    setSpring2({xy: cursorCoords(event.pageX, event.pageY)})

    if (
      target.tagName === 'BUTTON' || 
      target.parentElement.tagName === 'BUTTON' ||
      target.tagName === 'A' ||
      target.parentElement.tagName === 'A'
    ) {
      setPointer(true);
    } else {
      setPointer(false);
    }

    
  }
  

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Media at="lg">
        <Cursor transStyle={{transform: spring.xy.interpolate(trans)}} trail 
          transStyleTrail={{transform: spring2.xy.interpolate(pointer ? trans3 : trans2)}} pointerStyle={pointer} />
      </Media>
        
      <StylesProvider injectFirst>

        <Global styles={`
          
          html, body { width: 100%; height: 100%; overflow: hidden; margin: 0; padding: 0;}

          #___gatsby, #gatsby-focus-wrapper { width: 100%; height: 100%; overflow: hidden }

          .anchor {
            padding-top: ${isMobile ? mobiHeaderHeight : headerHeight};
          }
          
          .mainPageWrapper {
            position: relative;
            cursor: ${isMobile ? 'initial' : 'none'};
            width: 100%;
            height: 100%;
            overflow: hidden;

            main {
              position: relative;
              overflow-y: auto;
              scroll-behavior: smooth;
              height: 100%;
              padding-top: ${isMobile ? mobiHeaderHeight : headerHeight};
            }

          }
          
        `}/>

        <div className="mainPageWrapper" onMouseMove={handleMouseMove}>
          <main onScroll={onScroll}>{children}</main>
        </div>

      </StylesProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
