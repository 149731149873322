import { css } from '@emotion/core'
import { theme } from '../../common/site-variables';

export const getStyles = (isMobile: boolean) => {
    return css(`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;

        .footerContent {
            width: 100%;
            display: flex;
            flex-direction: ${isMobile ? 'column' : 'row'};

            .enquiry {
                position: relative;
                padding: 3%;
                width: ${isMobile ? 100 : 60}%;
                background-color: ${theme.lightBlue};
                border-radius: 0 7% 0 0 / 0 15% 0 0;
                box-shadow: ${ isMobile ? 'none' : '2px 5px 7px rgba(0, 0, 0, .2)'};
                margin-top: -2%;

                .copy {
                    position: absolute;
                    display: ${isMobile ? 'none' : 'flex'};
                    align-items: center;
                    bottom: 5%;
                }

            }

            .contactDiv {
                position: relative;
                width: ${isMobile ? 100 : 40}%;
                padding-top: ${isMobile ? 5 : 0}%;
                padding-bottom: ${isMobile ? 5 : 0}%;
                display: flex;
                flex-direction: ${isMobile ? 'column' : 'row'};

                justify-content: center;

                address {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0 2%;
                    text-align: center;
                }

                address > span, a {
                    margin-bottom: 2%;
                }

                .copy {
                    display: ${isMobile ? 'flex' : 'none'};
                    align-items: center;
                    justify-content: center;
                }

                .credits {
                    position: ${isMobile ? 'relative' : 'absolute'};
                    width: 100%;
                    bottom: 5%;
                    display: flex;
                    justify-content: center;
                    .swizzD {
                        margin-left: 2%;
                        color: ${theme.blue};
                    }
                    
                }
            }
        }

        a {
            text-decoration: none;
        }

    `);
}