import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import { useSpring } from 'react-spring'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import WhyUs from '../components/whyUs'
import WhatWeOffer from '../components/whatWeOffer'
import Header from '../components/header/header'
import AmbulanceInfo from '../components/ambulanceInfo'
import { Modal, IconButton } from '@material-ui/core'
import Footer from '../components/footer/footer'
import MembershipForm from '../components/membership-form'
import { theme } from '../common/site-variables'
import Snippet from '../components/snippet'
import Close from '@material-ui/icons/Close'
import { Media } from '../responsive/media'
import AmbulanceAnimation from '../components/ambulance-animation'

interface SubComponentPropTypes {
  isMobile: boolean;
  closeForm: () => void;
}
const ModalContent = (props: SubComponentPropTypes) => {
  const { isMobile, closeForm } = props;

  const modalStyle = css(`
    position: absolute;
    width: ${isMobile ? 95 : 50}%;
    height: ${isMobile ? 95 : 70}%;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3%;

    .modalWrap {
      position: relative;
      height: 100%;
      width: 100%;

      .closeBtn {
        position: absolute;
        top: 0;
        right: 2%;
        color: ${theme.red};
      }
  
      h2 {
        color: ${theme.blue};
      }
    }
  `);

  const handleClose = () => {
    closeForm();
  }

  return (
    <div css={modalStyle}>
      <div className="modalWrap">
        <h2>24/7 Membership Application</h2>
        <MembershipForm />

        <IconButton className="closeBtn" onClick={handleClose}><Close /></IconButton>
      </div>
    </div>
  )
}

const IndexPage = () => {
  const [showAppForm, setShowAppForm] = useState(false);
  const [scrollTop, setScrollTop] = useSpring(() => ({st: 0, config: { mass: 3, tension: 1350, friction: 100 } }));


  const handleScroll = (e) => {
    const target = e.target as HTMLElement;
    const scrollT =  target.scrollTop;
    setScrollTop({st: scrollT});
  }

  const toggleModal = (open: boolean) => () => {
    setShowAppForm(open);
  }

  return (
    <Layout onScroll={handleScroll}>

      <SEO title="Home" />
      <Header toggleApplicationForm={toggleModal(true)} />

      <Hero />

      <Snippet>
        Gentlecare Ambulance Service was establish in 2004 and incoporated on the 23rd of February 2005
      </Snippet>

      <WhyUs />
      
      <div id="services">
        <Media lessThan="md">
          <WhatWeOffer isMobile={true} toggleApplicationForm={toggleModal(true)}>
            <AmbulanceAnimation screenSize="s" scrollTop={scrollTop} />
          </WhatWeOffer>
        </Media>

        <Media at="md">
          <WhatWeOffer isMobile={true} toggleApplicationForm={toggleModal(true)}>
            <AmbulanceAnimation screenSize="md" scrollTop={scrollTop} />
          </WhatWeOffer>
        </Media>

        <Media at="lg">
          <WhatWeOffer isMobile={false} toggleApplicationForm={toggleModal(true)}>
            <AmbulanceAnimation screenSize="lg" scrollTop={scrollTop} />
          </WhatWeOffer>
        </Media>
      </div>
      
      <Media lessThan="lg">
        <AmbulanceInfo  isMobile={true} />
      </Media>

      <Media at="lg">
        <AmbulanceInfo  isMobile={false} />
      </Media>

      <Modal open={showAppForm} onClose={toggleModal(false)}>
        <div>
          <Media lessThan="lg">
            <ModalContent isMobile={true} closeForm={toggleModal(false)} />
          </Media>

          <Media at="lg">
            <ModalContent isMobile={false} closeForm={toggleModal(false)} />
          </Media>
        </div>
      </Modal>

      <Footer />

    </Layout>
  )
}

export default IndexPage
