import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Container, Grid } from '@material-ui/core'
import { theme } from '../common/site-variables'
import medPic from '../static-images/med_pattern.jpg'

const whyChooseUs = [
    {
        title: 'Affordable Cost for Patients', 
        paragraph: `
            We at Gentlecare are in this field for the people, not what we can gain from it. You don’t have to worry 
            about exuberant cost. We offer low competitive rates and even a lower 10% if you become a member.
        `
    },
    {
        title: 'Passionate about Health Care', 
        paragraph: `
            We care about you and will go above and beyond for you regardless of your economic background. We exhibit the utmost 
            compassion when providing emergency medical treatment and transportation.
        `
    },
    {
        title: 'Reliable & Professional Staff', 
        paragraph: `
            Our Staff includes professionals such as, doctors, nurses, EMTs, etc. As our front line representatives 
            to the public, we exhibit the highest level of professionalism possible. As such, all our staff has to undergo 
            training in Basic Life Support (BLS) / Advance Life Support (ALS) and International Trauma Life Support (ITLS)
        `
    },
    {
        title: '24/7 Reliability', 
        paragraph: `
            We have fully equipped Ambulances to transport the sick and injured at any time of the day, or any day of the week. 
            We are always on call as every second matters in the event of an emergency.
        `
    },
]

const WhyUs = () => {

    const styles = css(`
        width: 100%;
        
        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 20vh;
            background: linear-gradient(rgba(255,255,255, .5), rgba(255,255,255, .7)), url(${medPic});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            
            h1 {
                color: ${theme.blue};
                text-align: center;
                font-size: 1.5rem;
            }
        }


        .wrapper {
            position: sticky;
            padding: 5% 0;

            .points .content {
                span {
                    color: ${theme.blue};
                }
                h2 {
                    color: ${theme.red};
                }

                p {
                    color: ${theme.lightGray};
                }
            }
        }
    `);

    return (
        <section id="whyUs" className="anchor" css={styles}>

            <div className="header">
                <h1>Why us?</h1>
            </div>

            <Container className="wrapper">
                <Grid container>

                    {whyChooseUs.map((point, index) => (
                        <Grid item sm={12} md={6} lg={6} className="points">
                            <Container className="content">
                                <span>{`0${index + 1}`}</span> 
                                <h2>{point.title}</h2>
                                <p>{point.paragraph}</p>
                            </Container>
                        </Grid>
                    ))}
                </Grid>

            </Container>
        </section>
    )
}


export default WhyUs
